<template>
  <v-container id="users-list" fluid tag="section">
    <v-layout row wrap>
      <v-col cols="12">
        <!-- <base-material-card color="primary" outlined> -->
        <v-app-bar flat color="white">
          <v-spacer></v-spacer>
          <curso></curso>
        </v-app-bar>
        <v-app-bar flat color="white">
          <v-text-field
            flat
            solo
            prepend-icon="mdi-magnify"
            placeholder="Digite algo"
            v-model="search"
            hide-details
            class=""
          ></v-text-field>
          <v-btn icon>
            <v-icon>mdi-filter-variant</v-icon>
          </v-btn>
        </v-app-bar>
        <v-divider></v-divider>
        <!-- <v-card-text class="pa-0"> -->

        <v-data-table
          flat
          outlined
          no-results-text="Nenhum resultado encontrado"
          :headers="complex.headers"
          :search="search"
          :items="items"
          :footer-props="{
            'items-per-page-options': [
              10,
              25,
              50,
              { text: 'Todos', value: -1 },
            ],
          }"
          class="elevation-1"
          item-key="name"
          show-select
          v-model="complex.selected"
        >
          <template slot="item" slot-scope="props">
            <tr :class="{ 'grey--text': props.item.disabled }">
              <td>
                <v-checkbox
                  primary
                  hide-details
                  v-model="props.selected"
                ></v-checkbox>
              </td>
              <td>
                <!-- <v-avatar size="36px"> -->
                <v-img
                  v-if="props.item.thumbnail"
                  :src="props.item.thumbnail"
                  :alt="props.item.titulo"
                  :aspect-ratio="600 / 397"
                />
                <!-- </v-avatar> -->
              </td>
              <td>
                {{ props.item.titulo }}
                <v-chip v-if="props.item.disabled == true"> Encerrado </v-chip>
              </td>
              <td>{{ props.item.valor }}</td>
              <td>{{ props.item.duracao }} horas/aula</td>
              <td>{{ getNumMatriculados(props.item) }}</td>
              <td>
                <template v-if="!user.admin">
                  <v-btn
                    depressed
                    title="Duplicar"
                    icon
                    fab
                    dark
                    color="primary"
                    small
                    link
                    @click="duplicar(props.item)"
                  >
                    <v-icon>mdi-content-copy</v-icon>
                  </v-btn>
                  <v-btn
                    depressed
                    title="Matriculados"
                    icon
                    fab
                    dark
                    color="green"
                    small
                    link
                    :to="'matriculados/' + props.item['.key']"
                  >
                    <v-icon>mdi-account-details</v-icon>
                  </v-btn>
                  <v-btn
                    depressed
                    title="Editar Curso"
                    icon
                    fab
                    dark
                    color="green"
                    small
                    link
                    :to="'courses/' + props.item['.key']"
                  >
                    <v-icon>mdi-movie-edit</v-icon>
                  </v-btn>
                  <v-dialog
                    v-if="props.item.disabled == false"
                    v-model="props.item.dialog"
                    persistent
                    max-width="460"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        depressed
                        icon
                        fab
                        dark
                        color="red"
                        small
                        title="Desabilitar curso"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon>mdi-movie-off</v-icon>
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title class="headline">
                        Desativar curso
                      </v-card-title>
                      <v-card-text
                        ><v-alert type="error">
                          Cursos desativados não podem ser visualizados pelos
                          usuários.
                        </v-alert></v-card-text
                      >
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn text @click="props.item.dialog = false">
                          Cancelar
                        </v-btn>
                        <v-btn color="primary" @click="desativar(props.item)">
                          Desativar
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <v-dialog
                    v-if="props.item.disabled == true"
                    v-model="props.item.dialog"
                    persistent
                    max-width="460"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        depressed
                        icon
                        fab
                        dark
                        color="primary"
                        small
                        title="Ativar conta"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon>mdi-account-check</v-icon>
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title class="headline">
                        Ativar curso
                      </v-card-title>
                      <v-card-text
                        ><v-alert type="info">
                          Curso ativado.
                        </v-alert></v-card-text
                      >
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn text @click="props.item.dialog = false">
                          Cancelar
                        </v-btn>
                        <v-btn color="primary" @click="ativar(props.item)">
                          Ativar
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </template>
                <matricula-lote :curso="props.item"></matricula-lote>
                <matricula-lote-email
                  :curso="props.item"
                ></matricula-lote-email>
              </td>
            </tr>
          </template>
        </v-data-table>
        <!-- </v-card-text> -->
        <!-- </base-material-card> -->
      </v-col>
    </v-layout>
  </v-container>
</template>

<script>
import toastr from "toastr";
import firebase from "firebase/app";
// import {admin} from "firebase-admin";
import { db } from "@/db";

// Utilities
import { mapState } from "vuex";
export default {
  components: {
    Curso: () => import("@/views/dashboard/components/core/Curso"),
    MatriculaLote: () =>
      import("@/views/dashboard/components/core/MatriculaLote"),
    MatriculaLoteEmail: () =>
      import("@/views/dashboard/components/core/MatriculaLoteEmail"),
  },
  firebase: {
    items: db.ref("/cursos/"),
  },
  data() {
    return {
      dialog: false,
      search: "",
      items: [],
      complex: {
        selected: [],
        headers: [
          {
            text: "Thubmnail",
            value: "thumbnail",
          },
          {
            text: "Curso",
            value: "titulo",
          },
          {
            text: "Valor",
            value: "valor",
          },
          {
            text: "Duração",
            value: "duracao",
          },
          {
            text: "Alunos (Qtd)",
            value: "alunos",
          },
          {
            text: "Ações",
            value: "",
          },
        ],
        items: [],
      },
    };
  },
  methods: {
    duplicar(curso) {
      let newCurso = this.getValues(curso);
      newCurso.titulo = newCurso.titulo + " - Cópia";
      delete newCurso.matriculados;
      const ref = "cursos/";
      firebase
        .database()
        .ref(ref)
        .push(newCurso)
        .then(() => {
          // Update successful.
          toastr.success("Curso Duplicado com sucesso!", "MCS Assessoria e Treinamentos");
        })
        .catch((error) => {
          toastr.error(
            "Algo Inesperado aconteceu: " + error.message,
            "MCS Assessoria e Treinamentos"
          );
        });
    },
    getValues(object) {
      let keys = Object.keys(object);
      let values = Object.values(object);
      let obj = {};
      keys.forEach(function (key, index) {
        obj[key] = values[index];
      });
      return obj;
    },
    editar(user) {
      this.userEdit = user;
      this.dialogUser = true;
    },
    resetPassword(user) {
      toastr.info(
        "Solicitando redefinição de senha para: " +
          user.displayName +
          ". Aguarde ...",
        "MCS Assessoria e Treinamentos"
      );
      this.$http.defaults.headers.common["Authorization"] =
        "Bearer " + this.$store.state.user.refreshToken;
      // console.log(newUser)
      this.$http
        .get("/sendPasswordResetLink/" + user.email)
        .then((response) => {
          toastr.success(
            "E-mail com redefinição de senha para o usuário: " +
              user.displayName +
              " enviado com sucesso!",
            "MCS Assessoria e Treinamentos"
          );
          user.dialog = false;
        })
        .catch((error) => {
          toastr.error(
            "Algo Inesperado aconteceu: " + error.error,
            "MCS Assessoria e Treinamentos"
          );
        });
    },
    desativar(item) {
      toastr.info(
        "Desativando o curso: " + item.titulo + ". Aguarde ...",
        "MCS Assessoria e Treinamentos"
      );
    },
    ativar(user) {
      toastr.info(
        "Ativando o cadastro de: " + user.displayName + ". Aguarde ...",
        "MCS Assessoria e Treinamentos"
      );
      this.$http.defaults.headers.common["Authorization"] =
        "Bearer " + this.$store.state.user.refreshToken;
      // console.log(newUser)
      this.$http
        .get("/enableUser/" + user.uid)
        .then((response) => {
          toastr.success(
            "Usuário: " + user.displayName + " ativado com sucesso!",
            "MCS Assessoria e Treinamentos"
          );
          user.dialog = false;
        })
        .catch((error) => {
          toastr.error(
            "Algo Inesperado aconteceu: " + error.error,
            "MCS Assessoria e Treinamentos"
          );
        });
    },
    getNumMatriculados(curso) {
      if (curso && curso.matriculados) {
        return Object.keys(curso.matriculados).length;
      } else {
        return 0;
      }
    },
  },
  computed: {
    ...mapState(["user"]),
    userEdit: {
      get() {
        return this.$store.state.userEdit;
      },
      set(val) {
        this.$store.commit("SET_USER_EDIT", val);
      },
    },
    dialogUser: {
      get() {
        return this.$store.state.dialogUser;
      },
      set(val) {
        this.$store.commit("SET_DIALOG_USER", val);
      },
    },
  },
  created() {
    /*admin
      .auth()
      .getUsers()
      .then((getUsersResult) => {
        console.log("Successfully fetched user data:");
        getUsersResult.users.forEach((userRecord) => {
          console.log(userRecord);
        });

        console.log("Unable to find users corresponding to these identifiers:");
        getUsersResult.notFound.forEach((userIdentifier) => {
          console.log(userIdentifier);
        });
      })
      .catch((error) => {
        console.log("Error fetching user data:", error);
      });*/
  },
  updated() {
    // console.log(this.user);
  },
};
</script>